<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-text" />
          <h5 class="d-inline ml-2">Restaurant Types</h5>
          <div class="card-header-actions">
            <CButton color="primary" :to="{ name: 'Add New Type'}">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Type
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "RestaurantTypes",
  components: {
    BackendTable,
  },
};
</script>
