<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <CCard>
      <CCardBody class="px-1 pt-3 pb-0">
        <div class="d-flex flex-wrap">
          <div class="pr-2 mb-sm-0 mb-2">
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div class="flex-grow-1">
            <CPagination
              align="end"
              :dots='false'
              v-show="pages > 1"
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <!--
      clickable-rows
      @row-clicked="rowClicked"
      :active-page="1"
    -->
    <CDataTable
      hover
      striped
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :table-filter="{ external: true, lazy: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No restaurant types found!',
      }"
      @update:sorter-value="sorterValue"
      @update:table-filter-value="tableSearch"
      @update:column-filter-value="columnFilter"
      @pagination-change="paginationChange"
    >
      <!-- Type Image -->
      <template #type_image="{item}">
        <td>
          <CImg
            :src="item.type_image_path"
            class="c-avatar-img"
            style="width: 36px; height: 36px"
            placeholderColor="lightgray"
          />
        </td>
      </template>

      <template #show_details="{ item, index }">
        <td class="py-2">
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            @click="toggleDetails(item, index)"
          >
            {{ Boolean(item._toggled) ? "Hide" : "Show" }}
          </CButton>
        </td>
      </template>

      <template #details="{ item, index }">
        <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
          <CCardBody>
            <CMedia
              :aside-image-props="{
                width: '100%',
                height: 102,
                src: item.type_image_path,
              }"
            >
              <h4>{{ item.type }} </h4>
              <p class="text-muted">&nbsp;</p>
              <CButton size="sm"
                :to="{ name: 'Edit Type', params: { id: item.id }}"
                color="info"
                ><CIcon name="cil-align-left" /> Edit</CButton
              >
              <CButton
                size="sm"
                color="danger"
                class="ml-1"
                @click="deleteItem(item, index)"
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </CMedia>
          </CCardBody>
        </CCollapse>
      </template>
    </CDataTable>

    <CPagination
      v-show="pages > 1"
      :pages="pages"
      :active-page.sync="activePage"
      align="center"
    />
  </CCardBody>
</template>

<script>
export default {
  name: "BackendTable",
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "type_image", label: "", filter: false, sorter: false },
        { key: "id", label: "ID", filter: false, _style: "width: 5%" },
        { key: "type", label: "Name", filter: false, _style: "width: 20%" },
        { key: "fr", label: "FR", filter: false },
        { key: "en", label: "EN", filter: false },
        { key: "slug", filter: false, sorter: false },
        { key: "slug_fr", label: "Slug FR", filter: false, sorter: false },
        { key: "slug_en", label: "Slug EN", filter: false, sorter: false },
        { key: "show_details", label: "", _style: "min-width:1%", filter: false, sorter: false },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      collapseDuration: 0,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    };
  },
  mounted() {
    this.activePage =  this.queryPage;
    this.getAllItems();
  },
  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Restaurant Types', query: { page: this.activePage } });

      this.onTableChange();
    },
  },
  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; }
  },
  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.RESTAURANT_TYPES.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== ""
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      this.search = search;
      this.getAllItems();
    },
    columnFilter(filter) {
      //console.log(JSON.stringify(filter))
      // Object.keys(filter).forEach((key) => {
      //   console.log(key, filter[key]);
      // });
    },
    // rowClicked(item, index) {
    //   this.$router.push({ path: `users/${item.id}` });
    // },
    toggleDetails(item) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },


    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.RESTAURANT_TYPES.DELETE.replace("{id}", item.id)
              )
              .then((response) => {
                this.$delete(this.loadedItems, index);
                this.showErrorAlert = false
                this.successAlertMessage = {itemName: item.type, message: 'restaurant type deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

  },
};
</script>
